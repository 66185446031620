import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { post } from './../../src/fetchController'
import { connect } from 'react-redux'
import { Config } from './../../config'
import { push } from './../../src/navigation'
import Link from 'next/link'

class Breadcrumb extends Component {
  state = {
    crumbs: [],
    crumbsLoaded: false,
    id: 0
  }

  componentDidMount() {
    this.setState({ id: this.props.post.id })
    post('/wp-json/amfrest/v1/breadcrumbs', { id: this.props.post.id }).then(
      crumbs => {
        crumbs = crumbs.map(page => {
          page.url = page.url.replace(Config.wpUrl, '')
          return page
        })
        crumbs = crumbs.reverse()
        this.setState({ crumbs: crumbs, crumbsLoaded: true })
      }
    )
  }

  componentDidUpdate() {
    if (this.props.post.id === this.state.id) {
      return
    }
    this.setState({ id: this.props.post.id })

    post('/wp-json/amfrest/v1/breadcrumbs', { id: this.props.post.id }).then(
      crumbs => {
        crumbs = crumbs.map(page => {
          page.url = page.url.replace(Config.wpUrl, '')
          return page
        })
        crumbs = crumbs.reverse()
        this.setState({ crumbs: crumbs, crumbsLoaded: true })
      }
    )
  }

  navigate(url, as) {
    this.setState({ crumbsLoaded: false })
    push(url, as)
  }

  render() {
    return (
      <Row>
        <Col md="12" className="breadcrumb-outer">
          <nav
            className="breadcrumb-wrapper contentTop"
            aria-label="breadcrumb"
          >
            <ol
              className="breadcrumb"
              style={{ display: this.state.crumbsLoaded ? '' : 'none' }}
            >
              <li className="breadcrumb-item">
                <Link href="/">
                  <a>Home</a>
                </Link>
              </li>
              {this.state.crumbs.map((crumb, index) => {
                return (
                  <li className="breadcrumb-item" key={index}>
                    <Link href={crumb.url}>
                      <a>{crumb.title}</a>
                    </Link>
                  </li>
                )
              })}
              <li className="breadcrumb-item">
                <a>
                  {this.props.post.title
                    ? this.props.post.title.rendered
                    : null}
                </a>
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state, ownProps) =>
  function() {
    return {
      flexible: state.flexibleReducer
    }
  }

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Breadcrumb)
