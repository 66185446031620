import Layout from '../layouts/Layout'
import React, { Component } from 'react'
import fetch from 'isomorphic-unfetch'
import Error from './_error'
import { Container, Row, Col } from 'reactstrap'
import { Config } from '../config.js'
import SecurePage from './../hocs/securePage'
import Menu from '../components/Menu.js'
import Breadcrumb from '../components/page/Breadcrumb.js'
import Flexible from './../components/Flexible'
import { updateFlexible } from './../src/actions/flexibleAction'
import { post } from './../src/fetchController'
import { connect } from 'react-redux'
import { updateHeader } from './../src/actions/headerAction'
import Link from 'next/link'

class Post extends Component {
  static async getInitialProps(context) {
    let query = context.query
    var slug = query.slug
    var apiRoute = query.apiRoute

    if (!slug && !apiRoute) {
      let aspath = context.asPath.split('/')
      slug = aspath[aspath.length - 2]
      apiRoute = 'page'
    }

    const res = await fetch(
      `${Config.apiUrl}/wp-json/postlight/v1/${apiRoute}?slug=${slug}`
    )

    const post = await res.json()
    context.store.dispatch(updateFlexible(post.acf))
    return { post }
  }

  state = {
    post: {},
    subPages: [],
    subPageId: 0,
    subPagesLoaded: true
  }

  componentDidMount() {
    this.props.updateHeader(false)
    this.getSubPages()
  }

  getSubPages() {
    let id = this.props.post.id
    post('/wp-json/amfrest/v1/subpages', { id: id }).then(res => {
      res = res.map(page => {
        page.url = page.url.replace(Config.wpUrl, '')
        return page
      })
      this.setState({ subPages: res, subPageId: this.props.post.id })
    })
  }

  componentDidUpdate() {
    if (this.state.subPageId !== this.props.post.id) {
      this.getSubPages()
    }
  }

  render() {
    if (!this.props.post.title) return <Error statusCode={404} />

    return (
      <Layout>
        <Menu menu={this.props.headerMenu} />
        <Container className="pageLayout">
          <Breadcrumb post={this.props.post} />
          <Row>
            <Col md="3" key={1}>
              <aside>
                <div className="aside-title">MENY</div>
                <ul
                  className="list-group"
                  style={{ display: this.state.subPagesLoaded ? '' : 'none' }}
                >
                  <li className="list-group-item active">
                    <a>{this.props.post.title.rendered}</a>
                  </li>

                  {this.state.subPages.map((page,index) => {
                    return (
                      <li className="list-group-item" key={index}>
                        <Link href={page.url}>
                          <a>{page.title}</a>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </aside>
            </Col>
            <Col md="9" key={2}>
              <main>
                <h1>{this.props.post.title.rendered}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.post.content.rendered
                  }}
                />
              </main>
              <Flexible id={this.props.post} />
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateHeader: hasHero => {
    dispatch(updateHeader(hasHero))
  }
})

const mapStateToProps = (state, ownProps) =>
  function() {
    return {
      flexible: state.flexibleReducer
    }
  }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurePage(Post))
